import React from 'react';
import styles from './ContrtolledInput.module.css'

interface ControlInputProps {
  initValue: number
  labelText: string,
  name?: string,
  buttons: boolean,
  minValue?: number,
  maxValue?: number,
  onChange?: (name: string, value: number) => void
}

const minValueDefault = 0;
const maxValueDefault = 500;

const ControlledInput: React.FC<ControlInputProps> = (props) => {
  const minValue = props.minValue ?? minValueDefault;
  const maxValue = props.maxValue ?? maxValueDefault;
  
  const [value, setValue] = React.useState(props.initValue);

  const increment = React.useCallback(() => {
    if (value < maxValue) {
      setValue((state) => state + 1);
    }
  }, [value, maxValue]);

  const decrement = React.useCallback(() => {
    if (value > minValue) {
      setValue((state) => state - 1);
    }
  }, [value, minValue]);

  const onValueChanged = React.useCallback((e) => {
    if (!e.target.value) {
      setValue(minValue);
      return;
    }
    
    let number = Number.parseInt(e.target.value);

    if (Number.isNaN(number)) { return; }

    if (number < minValue) { number = minValue; }
    if (number > maxValue) { number = maxValue; }

    setValue(number);
  }, [minValue, maxValue]);

  React.useEffect(() => {
    if (props.name) {
      props.onChange?.(props.name, Number(value));
    }
  }, [value]);

  React.useEffect(() => {
    setValue(props.initValue);
  }, [props.initValue]);

  return (
    <div className={styles.item}>
      <div className={styles.item_label}>
        {props.labelText}
      </div>
      <div className={styles.container}>
        {
          props.buttons ? <button className={styles.button} onClick={decrement}>-</button> : <div></div>
        }
        <input type="text" value={value} onChange={onValueChanged} disabled={!props.buttons} />
        {
          props.buttons ? <button className={styles.button} onClick={increment}>+</button> : <div></div>
        }
      </div>
    </div>
  );
}

export default ControlledInput;