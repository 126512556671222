import cn from "classnames"
import styles from './ProfitCalculator.module.css';
import React from 'react';
import ControlledInput from "../ControlledInput/ControlledInput";
import Button from "../Button/Button";
import * as Rest from '../../Utils/Restaurant/Index';

interface Dictionary {
  [Key: string]: number;
}

const defaultData: Dictionary = {
  inputWaiters: 5,
  inputTables: 10,
  inputParallel: 10,
  inputMorning: 4,
  inputDay: 6,
  inputEvening: 15
}

const ProfitCalculator = () => {
  const [inputObject, setInputObject] = React.useState(defaultData);
  
  const [avgSum, setAvgSum] = React.useState(0);
  const [peoplesCount, setPeoplesCount] = React.useState(0);
  const [waitTime, setWaitTime] = React.useState(0);

  const onInputChange = React.useCallback((name: string, value: number) => {
    const obj = Object.assign({}, inputObject);
    obj[name] = value;
    // console.log(obj);
    setInputObject(obj);
  }, [inputObject]); 

  React.useEffect(() => {
    const waiters = inputObject['inputWaiters'];
    const tables = inputObject['inputTables'];
    const parallel = inputObject['inputParallel'];
    const morningPerHour = inputObject['inputMorning'];
    const dayPerHour = inputObject['inputDay'];
    const eveningHour = inputObject['inputEvening'];

    const result = Rest.simulate(waiters, tables, parallel, morningPerHour, dayPerHour, eveningHour);
    
    setAvgSum(result.avgCheckSum);
    setPeoplesCount(result.totalDayClientsCount);
    setWaitTime(result.avgWaitTime);
  }, [inputObject]);

  return (
    <div className={styles.calculator}>
      <ControlledInput buttons={true} onChange={onInputChange} name="inputWaiters" labelText={"Официантов в зале:"} initValue={inputObject.inputWaiters} />

      <ControlledInput buttons={true} onChange={onInputChange} name="inputTables" labelText={"Столиков в зале:"} initValue={inputObject.inputTables} />

      <ControlledInput buttons={true} onChange={onInputChange} name="inputParallel" labelText={"Блюд готовится одновременно:"} initValue={inputObject.inputParallel} />

      <ControlledInput maxValue={60} buttons={true} onChange={onInputChange} name="inputMorning" labelText={"Клиентов приходит каждый час утром:"} initValue={inputObject.inputMorning} />

      <ControlledInput maxValue={60} buttons={true} onChange={onInputChange} name="inputDay" labelText={"Клиентов приходит каждый час днем:"} initValue={inputObject.inputDay} />

      <ControlledInput maxValue={60} buttons={true} onChange={onInputChange} name="inputEvening" labelText={"Клиентов приходит каждый час вечером:"} initValue={inputObject.inputEvening} />

      <div className={styles.label}>
        Результат:
      </div>

      <ControlledInput buttons={false} name="avgSum" labelText={"Средний чек (рублей)"} initValue={avgSum} />
      
      <ControlledInput buttons={false} labelText={"Посещаемость (в сутки)"} initValue={peoplesCount} />
      
      <ControlledInput buttons={false} labelText={"Среднее время ожидания заказа"} initValue={waitTime} />
    </div>
  );
}

export default ProfitCalculator;