export class Log {
  static logEnabled: boolean = true;

  static Info(text: string) {
    if (this.logEnabled) {
      console.log(`[Info] ${text}`);
    }
  }

  static Debug(text: string) {
    if (this.logEnabled) {
      console.log(`[Debug] ${text}`);
    }
  }
}