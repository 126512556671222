import { Utils } from "../Utils";
import { EClientStatus, EOrderStatus, EQueueType } from "./Enums";
import { uniqueIDDD } from "./Index";
import { Log } from "./Log";
import Menu, { Eat } from "./RestaurantMenu";
import { Waiter, WaitersController } from "./WaitersController";

export class Client {
  private _uniqueID: number;
  public get uniqueID() {
    return this._uniqueID;
  }

  private _status: EClientStatus;
  public get status() {
    return this._status
  }
  public set status(value) {
    this._status = value;
  }

  private _timeToMakeOrder: number;
  private waiters: WaitersController;

  private _orderTotalSum: number = 0;
  public get orderTotalSum() {
    return this._orderTotalSum;
  }

  private order: Array<OrderItem>;

  private _waitTime: number = 0;
  public get waitTime() {
    return this._waitTime;
  }

  private _currentWaiter: Waiter;
  public set currentWaiter(value: Waiter) {
    this._currentWaiter = value;
  }

  constructor(waitersController: WaitersController, uniqueID: number) {
    this._uniqueID = uniqueID;
    this._status = EClientStatus.Welcome;
    this._timeToMakeOrder = Utils.randomNext(5, 15);
    this.waiters = waitersController;
    this._currentWaiter = { } as Waiter;
    this.order = [];
  }
  
  tick() {
    switch(this.status) {
      // 1. Начальный статус
      // (клиент просто ждет пока к нему свободный официант принесет меню)
      case EClientStatus.Welcome:
        Log.Info(`Клиент #${this.uniqueID} ожидает меню.`);
        this._waitTime++;
        break;

      // 2. Клиент получил меню и будет читать его %timeToMakeOrder% минут
      // как только он созреет к заказу то позовет официанта
      case EClientStatus.ReadMenu: 
        this._timeToMakeOrder--;

        if (this._timeToMakeOrder === 0) {
          Log.Info(`Клиент #${this.uniqueID} придумал что заказать и зовет официанта.`);
          this.order = this.chooseFood();
          this.status = EClientStatus.WaitingWaiter;
          this.waiters.addToQueue(this, EQueueType.WantsMakeOrder);
        } else {
          Log.Info(`Клиент ${this.uniqueID} всё ещё думает (осталось ${this._timeToMakeOrder} минут).`);
        }

        break;

      case EClientStatus.MakingOrder:

        Log.Info(`Клиент #${this.uniqueID} сообщил заказ официанту ${this._currentWaiter.uniqueID}`);
        this._currentWaiter.bringOrderFromClient(this, this.order);
        this.status = EClientStatus.WaitingOrder;

        break;

      case EClientStatus.WaitingOrder:

        this._waitTime++;
        
        break;

      case EClientStatus.Eating:
        const allEaten = this.order.every(x => x.status === EOrderStatus.Eaten);

        if (allEaten) {
          Log.Info(`Клиент #${this.uniqueID} съел всё что заказывал и уходит. Прощаемся с ним.`);
          this.status = EClientStatus.Exited;
          return;
        }

        const foodToEat = this.order.filter(x => x.status === EOrderStatus.Ready);

        if (foodToEat.length > 0) {
          foodToEat[0].doEat();
        } else {
          Log.Debug(`Клиент съел все что ему принести. Переходит в режим ожидания.`);
          this.status = EClientStatus.WaitingOrder;
        }

        break;
    }
  }

  waiterCome = () => {
    // пришел официант
    // статус клиента меняется на "делает заказ"
    this.status = EClientStatus.MakingOrder;
  }

  gotMenu = () => {
    Log.Info(`Клиент #${this.uniqueID} получил меню. Он будет думать ${this._timeToMakeOrder} минут.`);
    this.status = EClientStatus.ReadMenu;
  }

  gotOrder = (readyID: number) => {
    const food = this.order.find(x => x.uniqueID === readyID);

    Log.Info(`Клиент получил ${food?.eatInfo.name} (status = ${food?.status})`);
    this.status = EClientStatus.Eating;
  }

  chooseFood = (): Array<OrderItem> => {
    const order: Array<OrderItem> = [];

    Menu.forEach(category => {
      if (Math.random() <= category.chance || 
          (order.length === 0 && category.categoryName === 'Напитки')) 
      {
        const chooseIndex = Utils.randomNext(0, category.items.length);

        const choosenFood = category.items[chooseIndex];

        order.push(new OrderItem(this, choosenFood));
      }
    });

    const orderTotalSum = Utils.getAverage(order.map(x => x.eatInfo.price));
    const orderEatNames = order.map(x => `${x.eatInfo.name} == ${x.eatInfo.price}`);

    this._orderTotalSum = orderTotalSum;

    Log.Info(`Заказ клиента #${this.uniqueID}: ${orderEatNames.join(',')}. `);
    Log.Info(`Итоговая сумма заказа: ${orderTotalSum}.`);

    return order;
  }
}

export class OrderItem {
  private _uniqueID: number;
  public get uniqueID() {
    return this._uniqueID;
  }

  private _client: Client;
  public get client() {
    return this._client;
  }

  private _eatInfo: Eat;
  public get eatInfo() {
    return this._eatInfo;
  }

  private _leftTimeToEat: number;
  public get leftTimeToEat() {
    return this._leftTimeToEat;
  }

  private _status: EOrderStatus;
  public get status(): EOrderStatus {
    return this._status;
  }

  constructor(client: Client, eat: Eat) {
    this._uniqueID = uniqueIDDD.generate();
    this._client = client;
    this._eatInfo = eat;
    this._leftTimeToEat = Utils.randomNext(3, 6);
    this._status = EOrderStatus.Default;
  }

  doEat = () => {
    this._leftTimeToEat--;

    if (this.leftTimeToEat === 0) {
      this._status = EOrderStatus.Eaten;
      Log.Info(`Клиент #${this.client.uniqueID} съел ${this.eatInfo.name}!`);
      return;
    }

    if (this.leftTimeToEat < 0) {
      throw new Error('Ошибка! Блюдо уже съедено!');
    }

    Log.Info(`Клиент #${this.client.uniqueID} ест ${this.eatInfo.name}. Осталось ${this.leftTimeToEat}`);
  }

  setCooking = () => {
    this._status = EOrderStatus.Cooking;
  }
  setReady = () => {
    this._status = EOrderStatus.Ready;
  }
  setEaten = () => {
    this._status = EOrderStatus.Eaten;
  }
}