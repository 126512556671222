import { Utils } from "../Utils";
import { Client } from "./Client";
import { EClientStatus, EQueueType, ETimeType } from "./Enums";
import { Kitchen } from "./Kitchen";
import { Log } from "./Log";
import { UniqueID } from "./UniqueID";
import { WaitersController } from "./WaitersController";

const getTimeType = (hour: number): ETimeType => {
  if (hour >= 7 && hour < 12) return ETimeType.Morning;
  else if (hour >= 12 && hour < 18) return ETimeType.Day;
  else if (hour >= 18 && hour < 24) return ETimeType.Evening;
  else return ETimeType.Nignt;
}

const simulate = (workers: number, tables: number, parallelPrepare: number, inputMorningCount: number, inputDayCount: number, inputEveningCount: number): Result => {
  // максимум 60 человек в час.
  if (inputMorningCount > 60) { inputMorningCount = 60; }
  if (inputDayCount > 60) { inputDayCount = 60 }
  if (inputEveningCount > 60) { inputEveningCount = 60; }

  if (workers > 500) { workers = 500 }
  if (tables > 500) { tables = 500 }
  if (parallelPrepare > 500) { parallelPrepare = 500 }

  // ну а вдруг кто то додумается преедать сюда отрифательные числа?
  if (inputMorningCount < 0) { inputMorningCount = 0; }
  if (inputDayCount < 0) { inputDayCount = 0 }
  if (inputEveningCount < 0) { inputEveningCount = 0; }
    
  if ((workers <= 0 || tables <= 0 || parallelPrepare <= 0) ||
      (inputMorningCount === 0 && inputDayCount === 0 && inputEveningCount === 0)) 
    {
      // логика такова: 
      // 1 случай: если нет работников, столов или поваров то ресторан работать не может - возвращаем 0
      // 2 случай: если одновмеменно утром, днем и вечером никто не приходит - то смысла считать нет и возвращаем 0
      const result: Result = {
        totalDayClientsCount: 0,
        avgWaitTime: 0,
        avgCheckSum: 0
      }

      return result;
  }

  Log.logEnabled = false;
  
  const checks = new Array<number>(); // средний чек
  const waitTime = new Array<number>(); // среднее время ожидания
  
  let clients = new Array<Client>();

  const clientUniqueID = new UniqueID();
  const kitchen = new Kitchen(parallelPrepare);
  const controller = new WaitersController(workers, kitchen);

  console.log(`Моделирование работы ресторана. Входные параметры: waiters = ${workers}, tables = ${tables}, parallel = ${parallelPrepare}.`);

  const openHour: number = 9; // время открытия
  const closeHour: number = 24; // время закрытия
    
  // алгоритм у нас построен
  const morningPeriodMinutes = inputMorningCount > 0 ? Math.floor(60 / inputMorningCount) : 0;
  const dayPeriodMinutes = inputDayCount > 0 ? Math.floor(60 / inputDayCount) : 0;
  const eveningPeriodMinites = inputEveningCount > 0 ? Math.floor(60 / inputEveningCount) : 0;

  console.log(`Клиент заходит: ${``} утром каждые ${morningPeriodMinutes} минут, днем каждые ${dayPeriodMinutes} минут, вечером каждые ${eveningPeriodMinites} минут.`);

  let statsNotSpace = 0;
  let statsDayClients = 0;

  for(let hour = openHour; hour <= closeHour + 1; hour++) {
    for(let minute = 0; minute < 60; minute++) {
      const currentTime = Utils.prettyDate(hour, minute);
      
      Log.Debug(currentTime);
      
      kitchen.tick();
      clients.forEach(x => x.tick());
      controller.tick();

      const waitingForLastClients = (hour === 23) && (minute > 30);

      if (waitingForLastClients) {
        Log.Info('Ресторан скоро закрывается, заказы больше не принимаются.');
        Log.Info('Мы работаем до последнего клиента. Ждем, пока все клиенты доедят и уйдут');
      } else {
        // логика прихода клиентов

        const timeType = getTimeType(hour);

        if ((morningPeriodMinutes > 0 && minute % morningPeriodMinutes === 0 && timeType === ETimeType.Morning) ||
            (dayPeriodMinutes > 0 && minute % dayPeriodMinutes === 0 && timeType === ETimeType.Day) ||
            (eveningPeriodMinites > 0 && minute % eveningPeriodMinites === 0 && timeType === ETimeType.Evening)) 
        {
          if (clients.length === tables) {
            Log.Info('Пришел клиент, но у нас нет места =(');
            statsNotSpace++;
          } else {
            Log.Info('Пришел клиент');
            statsDayClients++;

            const client = new Client(controller, clientUniqueID.generate());
            clients.push(client);
            client.tick();

            controller.addToQueue(client, EQueueType.WantsMenu);
          }

          Log.Debug(`Занято столов: ${clients.length}/${tables}.`);
        }
      }

      for(let i = 0; i < clients.length; i++) {
        const client = clients[i];

        if (client.status === EClientStatus.Exited) {
          Log.Info('Записываем данные заказа в статистику.');

          checks.push(client.orderTotalSum);
          waitTime.push(client.waitTime);
        }
      }
      
      // убираем из списка клиентов, которые ушли
      clients = clients.filter(x => x.status !== EClientStatus.Exited);
    }
  }

  const avgCheckSum = Utils.getAverage(checks);
  const avgWaitTime = Utils.getAverage(waitTime);
  const totalDayClientsCount = statsDayClients;

  console.log('Моделирование завершено, результаты:');
  console.log(`Обслужено клиентов: ${totalDayClientsCount}. Нехватило места для ${statsNotSpace} клиентов.`);
  console.log(`Средний чек: ${avgCheckSum}р.`);
  console.log(`Среднее время ожидания: ${avgWaitTime} минут.`);

  const result: Result = {
    totalDayClientsCount,
    avgCheckSum,
    avgWaitTime
  };

  return result;
}

export interface Result {
  totalDayClientsCount: number,
  avgCheckSum: number,
  avgWaitTime: number
}

export const uniqueIDDD = new UniqueID();

export {
  simulate
};