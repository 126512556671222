import { NavLink } from 'react-router-dom';
import Button from '../../components/Button/Button';
import styles from './MainPage.module.css';
import cn from "classnames"

const MainPage = () => {
  return (
    <>
      <div className={styles.big}>
        <div className={styles.big_picture}>1</div>
        <div className={styles.row}>
          <div className={styles.big_first}>
            Ресторан <br />
            <strong>
              Французской <br />
              Кухни 
            </strong>
          </div>
          <div className={styles.big_second}>
            Эксклюзивная подача <br /> 
            изысканых французских блюд, <br />
            а так же знаменитых блюд <br /> 
            со всех уголков мира
          </div>
          <div className={styles.big_button}>
            <NavLink className={styles.link} to="/menu">
              <Button text="Меню" size="medium" />
            </NavLink>
          </div>
        </div>
        <div className={styles.one}>
            1
        </div>
      </div>
      <div className={styles.categories}>
        <div className={styles.categories_title}>
          МЕНЮ
        </div>
        <div className={styles.categories_list}>
          <div className={cn(styles.categories_list_item, styles.salat)}>
            <div>
              Салаты
            </div>
          </div>
          <div className={cn(styles.categories_list_item, styles.sup)}>
            <div>
              Супы
            </div>
          </div>
          <div className={cn(styles.categories_list_item, styles.bluda)}>
            <div>
              Горячие блюда
            </div>
          </div>
          <div className={cn(styles.categories_list_item, styles.garn)}>
            <div>
              Гарниры
            </div>
          </div>
        </div>
        <div className={styles.categories_button}>
          <NavLink className={styles.link} to="/menu">
            <Button text="Открыть меню" size="big" />
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default MainPage;
