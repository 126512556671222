/**
  Класс, который генериурет уникальные ID
*/
export class UniqueID {
  private _uniqueCounter: number = 0;

  generate(): number {
    return ++this._uniqueCounter;
  };

  get currentID(): number {
    return this._uniqueCounter;
  }
}