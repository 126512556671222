import { Client, OrderItem } from "./Client";
import { EQueueType, EWaiterStatus } from "./Enums";
import { Kitchen } from "./Kitchen";
import { Log } from "./Log";
import { UniqueID } from "./UniqueID";

export class WaitersController {  
  private _kitchen: Kitchen;
  private _clientsQueue: Array<QueueItem>;
  private _waiters: Array<Waiter>

  constructor(totalWaiters: number, kitchen: Kitchen) {
    this._kitchen = kitchen;
    this._clientsQueue = [];
    this._waiters = [];

    const uniqueID = new UniqueID();
    for(let i = 0; i < totalWaiters; i++) {
      const id = uniqueID.generate();
      this._waiters.push(new Waiter(id, kitchen));
    }
  }

  getAvaliableWaiters = () => {
    return this._waiters.filter(x => x.status === EWaiterStatus.Free);
  }

  tick = () => {
    const readyOrdersFromKitchen = this._kitchen.getReadyOrders();
    if (readyOrdersFromKitchen.length > 0) {
      Log.Info(`[Waiters] Кухня сообщила о ${readyOrdersFromKitchen.length} готовых заказах`);

      readyOrdersFromKitchen.forEach(order => this.addToQueue(order.client, EQueueType.BringReadyEat, order.uniqueID));
    }

    const avaliableWaiters = this.getAvaliableWaiters();
    let avaliableWaitersCount = avaliableWaiters.length;
    
    this._waiters.forEach(x => x.tick());

    if (avaliableWaitersCount === 0) {
      Log.Info('Все официанты заняты.');
      return;
    }

    const sortedClientQueue = this._clientsQueue.sort((a, b) => a.type - b.type);

    Log.Info(`Задач для официантов: ${sortedClientQueue.length}.`);
    
    if(sortedClientQueue.length === 0) {
      Log.Info('Есть свободные официанты, но им нечего делать.');
      return;
    }

    const tasksCount = sortedClientQueue.length > avaliableWaitersCount ? avaliableWaitersCount : sortedClientQueue.length;

    for(let i = 0; i < tasksCount; i++) {
      const client: Client = sortedClientQueue[i].client;
      const type: EQueueType = sortedClientQueue[i].type;
      const readyID: number = sortedClientQueue[i].readyID;

      client.currentWaiter = avaliableWaiters[i];

      switch(type) {
        case EQueueType.WantsMenu:
          avaliableWaiters[i].bringMenu(client);
          break;

        case EQueueType.WantsMakeOrder:
          avaliableWaiters[i].takeOrder(client);
          break;

        case EQueueType.BringReadyEat:
          avaliableWaiters[i].bringFood(client, readyID);
          break;
      }

      sortedClientQueue[i].setReady();

      this._clientsQueue = sortedClientQueue.filter(x => !x.ready);
    }
  }

  addToQueue = (client: Client, type: EQueueType, readyID: number = -1) => {
    this._clientsQueue.push(new QueueItem(client, type, readyID));
  }
}

export class Waiter {
  private _uniqueID: number;
  public get uniqueID() {
    return this._uniqueID;
  }

  private _kitchen: Kitchen;

  private _status: EWaiterStatus;
  public get status() {
    return this._status;
  }

  private _notAvaliableMinutes: number = 0;

  constructor(uniqueID: number, kitchen: Kitchen) {
    this._uniqueID = uniqueID;
    this._kitchen = kitchen;
    this._status = EWaiterStatus.Free;
  }

  bringFood = (client: Client, readyID: number) => {
    if (this.status !== EWaiterStatus.Free) {
      throw new Error('Мы пытаемся поручить работу официанту который уже занят.');
    }

    Log.Info(`Официант ${this.uniqueID} несет еду клиенту #${client.uniqueID}.`);
    this._notAvaliableMinutes = 2;
    client.gotOrder(readyID);
    this._status = EWaiterStatus.OrderBring;
  }

  tick = () => {
    if (this._notAvaliableMinutes > 0) {
      this._notAvaliableMinutes--;
    }

    if (this._notAvaliableMinutes === 0) {
      this._status = EWaiterStatus.Free;
    }
  }

  bringOrderFromClient = (client: Client, order: OrderItem[]) => {
    Log.Info(`Официант #${this.uniqueID} сообщает заказ клиента ${client.uniqueID} на кухню.`);

    order.forEach(x => this._kitchen.addNewItem(x));
  }

  bringMenu = (client: Client) => {
    if (this.status !== EWaiterStatus.Free) {
      throw new Error('Мы пытаемся поручить работу официанту который уже занят.');
    }

    Log.Info(`Официант #${this.uniqueID} несет меню клиенту ${client.uniqueID}`);
    this._notAvaliableMinutes = 2;
    client.gotMenu();
    this._status = EWaiterStatus.MenuBring;
  }

  takeOrder = (client: Client) => {
    if (this.status !== EWaiterStatus.Free) {
      throw new Error('Мы пытаемся поручить работу официанту который уже занят.');
    }

    Log.Info(`Официант #${this.uniqueID} идет записывать заказ клиента #${client.uniqueID}.`);
    this._notAvaliableMinutes = 2;
    client.waiterCome();
    this._status = EWaiterStatus.OrderTaking;
  }
}

export class QueueItem {
  private _client: Client;
  public get client() {
    return this._client;
  }

  private _type: EQueueType;
  public get type() {
    return this._type;
  }
  
  private _readyID: number;
  public get readyID() {
    return this._readyID;
  }

  private _ready: boolean;
  public get ready() {
    return this._ready;
  }

  constructor(client: Client, type: EQueueType, readyID: number) {
    this._client = client;
    this._type = type;
    this._readyID = readyID;
    this._ready = false;
  }

  setReady = () => {
    this._ready = true;
  }
}