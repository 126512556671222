const Menu: Category[] = [
  {
    categoryName: 'Салаты',
    chance: 0.4,
    items: [
      {
        name: 'Салат «Цезарь»',
        price: 390,
        minWaitTime: 10,
        maxWaitTime: 15
      },
      {
        name: 'Салат «Греческий»',
        price: 460,
        minWaitTime: 10,
        maxWaitTime: 15
      },
      {
        name: 'Салат «Овощной с говядиной грирь»',
        price: 490,
        minWaitTime: 10,
        maxWaitTime: 20
      },
      {
        name: 'Салат овощной с командроским кальмаром',
        price: 370,
        minWaitTime: 10,
        maxWaitTime: 15
      },
      {
        name: 'Салат с лососем',
        price: 490,
        minWaitTime: 10,
        maxWaitTime: 15
      },
      {
        name: 'Креветки гриль со свежим салатом',
        price: 650,
        minWaitTime: 10,
        maxWaitTime: 20
      },
      {
        name: 'Салат «Овощи гриль»',
        price: 390,
        minWaitTime: 10,
        maxWaitTime: 15
      },
      {
        name: 'Салат «С овощами и сыром фета»',
        price: 350,
        minWaitTime: 10,
        maxWaitTime: 15
      },
      {
        name: 'Салат с бужаниной',
        price: 470,
        minWaitTime: 10,
        maxWaitTime: 15
      }
    ]
  },
  {
    categoryName: 'Супы',
    chance: 0.4,
    items: [
      {
        name: 'Борщ с говядиной',
        price: 270,
        minWaitTime: 5,
        maxWaitTime: 10
      },
      {
        name: 'Гороховый суп-пюре',
        price: 220,
        minWaitTime: 5,
        maxWaitTime: 10
      },
      {
        name: 'Суп-пюре грибной',
        price: 240,
        minWaitTime: 5,
        maxWaitTime: 10
      },
      {
        name: 'Суп с куриной ножкой',
        price: 220,
        minWaitTime: 5,
        maxWaitTime: 10
      },
      {
        name: 'Суп с рыбой и морепродуктами',
        price: 390,
        minWaitTime: 10,
        maxWaitTime: 15
      },
      {
        name: 'Томатный суп',
        price: 220,
        minWaitTime: 5,
        maxWaitTime: 10
      },
      {
        name: 'Венгерский суп-гуляш',
        price: 270,
        minWaitTime: 10,
        maxWaitTime: 15
      },
    ]
  },
  {
    categoryName: 'Горячие блюда',
    chance: 0.4,
    items: [
      {
        name: 'Вырезка из говядины на гриле',
        price: 270,
        minWaitTime: 30,
        maxWaitTime: 40
      },
      {
        name: 'Фланк-стейк',
        price: 220,
        minWaitTime: 30,
        maxWaitTime: 40
      },
      {
        name: 'Телятина тушеная',
        price: 240,
        minWaitTime: 30,
        maxWaitTime: 40
      },
      {
        name: 'Свинина на кости гриль',
        price: 220,
        minWaitTime: 30,
        maxWaitTime: 40
      },
      {
        name: 'Рублёный бифштекс',
        price: 390,
        minWaitTime: 30,
        maxWaitTime: 40
      },
      {
        name: 'Свининые язычки',
        price: 220,
        minWaitTime: 30,
        maxWaitTime: 40
      },
      {
        name: 'Утиное филе',
        price: 290,
        minWaitTime: 30,
        maxWaitTime: 40
      },
      {
        name: 'Жареные мясные шарики',
        price: 290,
        minWaitTime: 20,
        maxWaitTime: 30
      },
      {
        name: 'Филе мильон с кукурузой гриль',
        price: 290,
        minWaitTime: 20,
        maxWaitTime: 30
      },
    ]
  },
  {
    categoryName: 'Напитки',
    chance: 0.50,
    items: [
      {
        name: 'Кофе',
        price: 110,
        minWaitTime: 5,
        maxWaitTime: 7
      },
      {
        name: 'Чай',
        price: 120,
        minWaitTime: 5,
        maxWaitTime: 7
      },
      {
        name: 'Сок',
        price: 65,
        minWaitTime: 3,
        maxWaitTime: 5
      },
      {
        name: 'Вода',
        price: 35,
        minWaitTime: 3,
        maxWaitTime: 5
      }
    ]
  }
]

interface Category {
  categoryName: string,
  items: Eat[],
  chance: number
}

export interface Eat {
  name: string,
  price: number,
  minWaitTime: number,
  maxWaitTime: number
} 

export default Menu;