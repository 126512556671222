export class Utils {
  static getAverage(array: Array<number>) :number {
    const sum = array.reduce((sum, current) => sum += current, 0) / array.length;

    return Math.round((sum + Number.EPSILON) * 100) / 100;
  }

  /* 
    Красиво выводит дату, например 09:45
  */
  static prettyDate(hours: number, minites: number): string {
    const makeItCool = (num: number) => num.toString().padStart(2, '0');
    
    return `${makeItCool(hours)}:${makeItCool(minites)}`;
  }; 

  static randomNext(min: number, max: number) {
    //return Math.random() * (max - min) + min;
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
  }
}