import React from 'react';
import styles from './Button.module.css';
import cn from "classnames"

interface ButtonProps {
  text: string,
  size: 'big' | 'medium',
  onClick?: Function
}

const Button: React.FC<ButtonProps> = (props) => {  
  const clickHandler = React.useCallback(() => {
    props.onClick?.();
  }, [props]);
  
  return (
    <input onClick={clickHandler} className={props.size === 'big' ? cn(styles.button, styles.big) : cn(styles.button, styles.medium)} type="button" value={props.text} />
  )
}

export default Button;