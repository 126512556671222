export enum ETimeType {
  Morning,
  Day, 
  Evening,
  Nignt
}

export enum EQueueType {
  BringReadyEat,
  WantsMenu,
  WantsMakeOrder,
  RemoveMe
}

export enum EClientStatus {
  /* 
    Клиент только пришел.
    Через пару минут к нему подойдет официант.
    Клиенту самому звать официанта не нужно.
  */
  Welcome,

  /* 
    Изучает меню
  */
  ReadMenu,

  /* 
    Ждет официанта чтобы сделать заказ
  */
  WaitingWaiter,

  /* 
    Сообщает заказ официанту
  */
  MakingOrder,

  /* 
    Клиент сделал заказ и теперь ожидает
  */
  WaitingOrder,

  /* 
    Кушает
  */
  Eating,

  /* 
    Расплачивается
  */
  PayOff,

  /* 
    Уходит
  */
  Exited
}

export enum EWaiterStatus {
  Free,

  MenuBring,

  OrderTaking,

  OrderBring
}

export enum EOrderStatus {
  Default, 
  Cooking,
  Ready,
  Eaten
}