import cn from "classnames"
import styles from './Header.module.css';
import { NavLink} from "react-router-dom";

const Header = () => {
  return (
    <div className={styles.header_container}>
      <div className={styles.header}>
        <div className={styles.logo}>
          Temps
        </div>
        <div className={styles.navigation}>
          <NavLink className={styles.link} exact to="/">
            Главная
          </NavLink>
          <NavLink className={styles.link} to="/menu">
            Меню ресторана
          </NavLink>
          <NavLink className={styles.link} to="/franchise">
            Франшиза
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Header;