import cn from "classnames"
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footer_container}>
      <div className={styles.footer}>
        <div className={styles.content}>
          <div className={styles.text}>
            <strong>«Temps»</strong><br />
            Сеть фирменных ресторанов
          </div>
          <div> 
            Контакты<br />
            <input type="button" className={cn(styles.button, styles.button_vk)} />
            <input type="button" className={cn(styles.button, styles.button_instagram)} />
            <input type="button" className={cn(styles.button, styles.button_facebook)} />
          </div>
          <div>
            +7 900 250 25 25 <br />
            +7 900 250 75 25
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Footer;