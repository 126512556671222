import { Route, Switch } from "react-router-dom";
import AboutPage from "../../pages/AboutPage/AboutPage";
import MainPage from "../../pages/MainPage/MainPage";
import MenuPage from "../../pages/MenuPage/MenuPage";
import styles from './Main.module.css';

const Main = () => {
  return (
    <div className={styles.main_container}>
      <div className={styles.main}>
        <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route exact path="/menu">
            <MenuPage />
          </Route>
          <Route exact path="/about">
            <AboutPage /> 
          </Route>
          <Route exact path="/franchise">
            <AboutPage /> 
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Main;