import { Utils } from "../Utils";
import { OrderItem } from "./Client";
import { Log } from "./Log";

export class Kitchen {
  private _parallelPrerare: number;
  private _ordersInProgress: Array<KitchenOrder>;
  private _ordersReady: Array<OrderItem>;

  constructor(parallelPrepare: number) {
    this._parallelPrerare = parallelPrepare;
    this._ordersInProgress = [];
    this._ordersReady = [];
  }

  tick = () => {
    if (this._ordersInProgress.length === 0) {
      Log.Info(`[Кухня] Все заказы выполнены. Отдыхаем.`);
      return;
    }

    const count = this._parallelPrerare > this._ordersInProgress.length ? this._ordersInProgress.length : this._parallelPrerare;

    for(let i = 0; i < count; i++) {
      this._ordersInProgress[i].tick();

      if (this._ordersInProgress[i].IsReady) {
        Log.Info(`"${this._ordersInProgress[i].orderItem.eatInfo.name} готов!"`);

        // достаем из обьекта KitchenItem обьект OrderItem
        this._ordersInProgress[i].orderItem.setReady();
        this._ordersReady.push(this._ordersInProgress[i].orderItem);
      }
    }

    this._ordersInProgress = this._ordersInProgress.filter(x => !x.IsReady);

    Log.Info('==========');
    Log.Info('Статистика кухни:');
    for(let i = 0; i < this._ordersInProgress.length; i++) {
      const item = this._ordersInProgress[i];

      Log.Info(`[${i+1}] ${item.orderItem.eatInfo.name} (до готовности ${item.timeToReady} минут)`);
    }
    Log.Info('==========');
  }

  addNewItem = (item: OrderItem) => {
    Log.Info(`[Кухня] Принят заказ на "${item.eatInfo.name}"`);
    item.setCooking();
    this._ordersInProgress.push(new KitchenOrder(item));
  }

  getReadyOrders = (): OrderItem[] => {
    const array = [...this._ordersReady];
    this._ordersReady = [];
    return array;
  }
}

export class KitchenOrder {
  private _orderItem: OrderItem;
  public get orderItem(): OrderItem {
    return this._orderItem;
  }

  private _timeToReady: number;
  public get timeToReady() {
    return this._timeToReady;
  }

  constructor(orderItem: OrderItem) {
    this._orderItem = orderItem;
    this._timeToReady = Utils.randomNext(orderItem.eatInfo.minWaitTime, orderItem.eatInfo.maxWaitTime);
  }

  public get IsReady(): boolean {
    return this._timeToReady === 0;
  }

  tick = () => {
    this._timeToReady--;

    if (this._timeToReady < 0) {
      throw new Error(`Ошибка! Блюдо уже готово но был вызван метод.`);
    }
  }
}