import ProfitCalculator from '../../components/ProfitCalculator/ProfitCalculator';
import styles from './AboutPage.module.css';
import picture from './res/image.png';

const AboutPage = () => {
  return (
    <div className={styles.about_container}>
      <div className={styles.text_container}>
        <div className={styles.text_leftside}>
          <p className={styles.text_title}>
            Франшиза ресторанов «Temps»
          </p>
          <p>
            На данной странице вы можете ознакомиться <br />
            со средними показателями наших ресторанов.<br /> 
          </p>
          <p>
            Вы можете изменять следующие параметры: <br />
            1. «Официанты» <br /> 
            2. «Столики» <br />
            3. «Готовится одновременно блюд» <br />
            4. «Количество посетителей каждый час» <br />
          </p>
          <p>
            Исходя из полученных данных будут расчитаны: <br />
            1. Средний чек в ресторане <br />
            2. Примерная посещаемость ресторана (в сутки) <br />
            3. Среднее время ожидания заказа.
          </p>
        </div>
        <div className={styles.text_rightside}>
          <p>
            <img src={picture} alt="11" />
          </p>
        </div>
      </div>
      <ProfitCalculator />
    </div>
  );
}

export default AboutPage;