import styles from './MenuPage.module.css';
import restaurantMenu from '../../Utils/Restaurant/RestaurantMenu';
import cn from "classnames"

const MenuPage = () => {
  return (
    <div className={styles.menu_container}>
      <div className={styles.title}>
        <strong>
          Меню
        </strong>
      </div>
      <div className={styles.menu}>
        { 
          restaurantMenu.map(category => 
            <div className={styles.category}>
              <div className={styles.category_name}>
                { category.categoryName }
              </div>
              <div className={styles.item}>
                <div className={styles.item_name}>
                  <strong>
                    Название блюда
                  </strong>
                </div>
                <div className={styles.item_wait_time}>
                  <strong>
                    Время ожидания
                  </strong>
                </div>
                <div className={styles.item_price}>
                  <strong>
                    Стоимость
                  </strong>
                </div>
              </div>
              <div>
                { 
                  category.items.map(item => 
                    <div className={cn(styles.item, styles.colorized)}>
                      <div className={styles.item_name}>
                        { item.name }
                      </div>
                      <div className={styles.item_wait_time}>
                        { `${item.minWaitTime}-${item.maxWaitTime} минут` }
                      </div>
                      <div className={styles.item_price}>
                        { `${item.price} Р.` }
                      </div>
                    </div>
                  )
                }
                
              </div>              
            </div>
          )
        }
      </div>
    </div>
  );
}

export default MenuPage;